import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import vants from "@/plugins/vant"
import globle from './store/globle.vue'
import { Dialog } from "vant";
import Base64 from 'js-base64'
// import { fromBase64 } from 'js-base64'
Vue.prototype.$Dialog = Dialog
Vue.config.productionTip = false
Vue.prototype.$globle = globle
Vue.prototype.$bus = new Vue();
// Vue.prototype.$Base64 = fromBase64;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

