import Vue from 'vue'
import Vuex from 'vuex'
import{ AES } from "../plugins/md5"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //存储token
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    step:"drag",
    state3d:0
  },
  mutations: {
    changeLogin (state, user) {
      state.Authorization = user.Authorization
      console.log('state--Vuex-------')
      let token =  AES.encrypt(user.Authorization).toString()
      // let token =  this.$Base64.encode.encode(user.Authorization)
      localStorage.setItem('Authorization', token);
      // localStorage.setItem('Authorization', user.Authorization);
    },
    changeStep(state,status){     
      state.step=status
    },
  
  },

  getters:{
    getStatus(state){
				return  state.step
    }
  },
  actions: {
  
  },
  modules: {
  }
})
