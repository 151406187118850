import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/PmPower.vue"),
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("../views/Product.vue"),
  },
  {
    path: "/2droom",
    name: "2Droom",
    component: () => import("../views/2Droom.vue"),
  },
  {
    path: "/testing",
    name: "Testing",
    component: () => import("../views/Testing.vue"),
  }
];
const router = new VueRouter({
  // mode: "history",
  routes,
});
router.afterEach((to,from) => {
  if(to.path === '/product' && from.path === '/2droom'){
    window.state3dmax = 0
    console.log(window.state3dmax)
  }
  
})
export default router;
