import Vue from 'vue';
import { Button } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Switch } from 'vant';
import { Popup } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { NumberKeyboard } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Icon } from 'vant';
import { Dialog } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Slider } from 'vant';

Vue.use(Slider);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Dialog);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NumberKeyboard);
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Switch);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);