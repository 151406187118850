<template>
  <div id="app">
    <div>
      <div class="head" v-if="headShow">
        <img
          @click="goBack"
          class="backIcon"
          src="@/assets/images/back.png"
          alt=""
        />
        <p class="title">
          <span>{{ shipToPartyCnName }}</span>
          <span class="product-name">{{ system }}</span>
        </p>
        <img
          @click="goHome"
          class="backhomeIcon"
          src="@/assets/images/backhome.png"
          alt=""
        />
      </div>
      <div class="steps" v-if="headShow">
        <span
          v-show="item == step"
          v-for="(item, index) in stepsArr"
          :key="index"
        ></span>
      </div>
      <div class="three" v-show="show3D">
        <img
          @click="open2d"
          v-show="show3D"
          src="./assets/images/twod.png"
          alt=""
        />
        <iframe
          :src="src"
          ref="iframe"
          width="100%"
          height="90%"
          scrolling="no"
          frameborder="0"
        ></iframe>
      </div>
    </div>
    <keep-alive include="Product">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
export default {
  data() {
    return {
      shipToPartyCnName: "",
      system: "",
      routeName: "Product",
      step: 1,
      stepsArr: [1, 2, 3, 4, 5, 6, 7],
      show3D: false,
      iframeWin: {},
      src: "./3d/index.html",
      dataObj: {},
      headShow: true,
      state3d: 0,
    };
  },
  computed: {
    topStep() {
      return this.$store.getters.getStatus;
    },
  },
  watch: {
    topStep(n) {
      console.log(n, "页面的变化");
      if (n == "drag") {
        this.step = 2;
      }
      if (n == "plan") {
        this.step = 3;
      }
      if (n == "adjustment") {
        this.step = 4;
      }
      if (n == "danger") {
        this.step = 5;
      }
      if (n == "angle") {
        this.step = 6;
      }
      if (n == "drawing") {
        this.step = 7;
      }
      localStorage.setItem("topStep", this.step);
    },
    $route(n, o) {
      this.routeName = n.name;
      if (n.name == "Home") {
        this.headShow = false;
      } else {
        this.headShow = true;
      }
      if (n.name == "Product") {
        this.step = 1;
        // this.open2d()
      }
      // if (n.name == '2Droom' && this.step == 1) {
      if (n.name == "2Droom") {
        this.step = 2;
      }
      localStorage.setItem("topStep", this.step);
      // if (n.name == "GenerateDrawing") {
      //   this.step = 7;
      // }
    },
  },
  mounted() {
    console.log('11111111')
    sessionStorage.setItem("state3d", "0");
    window.state3dmax = 0;
    localStorage.removeItem("Authorization");
    this.iframeWin = this.$refs.iframe.contentWindow;
    this.$bus.$on("open3d", (dataObj) => {
      this.dataObj = dataObj;
      this.open3d(this.dataObj);
    });
    this.$bus.$on("headValue", (shipToPartyCnName, system) => {
      this.shipToPartyCnName = shipToPartyCnName;
      this.system = system;
    });
  },
  created() {
    this.$bus.$on("goBack", () => {
      this.goBack();
    });
  },
  methods: {
    refreshSaveData() {
      this.step = localStorage.getItem("topStep");
    },
    open2d() {
      this.show3D = false;
      let data = {
        type: "0",
      };
      this.sendMsgTo3D(data);
      this.$bus.$emit("open2d");
    },
    open3d(dataObj) {
      this.show3D = true;
      let data = {
        type: "1",
        data: dataObj,
      };
      this.sendMsgTo3D(data);
    },
    sendMsgTo3D(data) {
      console.log(data, "*******************");
      this.variable(data);
      console.log(data, "传递给3D的数据------>");
      this.iframeWin.postMessage(data, "*");
    },
    variable(data) {
      // 配成符合3d需求的数据  因为3d不改了 所以....
      if (data.type == "1") {
        // data.data.zhuForm = [
        //   {x: 303, y: 570, width: 18, height: 35},
        //   // {x: 0, y: 152, width: 250, height: 24},
        //   // {x: 0, y: 402, width: 114, height: 43}
        // ]

        var homedataInfo = JSON.parse(sessionStorage.getItem("homeData"));
        // homedataInfo.zhuForm = [
        //   {x: 303, y: 570, width: 18, height: 35},
        // ]
        if (data.data.machineRoomForm.patientWidth <= 0) {
          data.data.machineRoomForm.patientPosition = -1;
        }

        let temporary;
        if (
          homedataInfo.machineRoomForm !== null &&
          homedataInfo.machineRoomForm.slideDirection !== ""
        ) {
          if (homedataInfo.machineRoomForm.slideDirection === "上") {
            temporary = 0;
          } else if (homedataInfo.machineRoomForm.slideDirection === "下") {
            temporary = 1;
          } else if (homedataInfo.machineRoomForm.slideDirection === "左") {
            temporary = 2;
          } else if (homedataInfo.machineRoomForm.slideDirection === "右") {
            temporary = 3;
          }
        }
        if (
          homedataInfo.machineRoomForm.patientPosition === "上" ||
          homedataInfo.machineRoomForm.patientPosition === "下"
        ) {
          if (homedataInfo.machineRoomForm.slideDirection === "左") {
            temporary = 3;
          } else if (homedataInfo.machineRoomForm.slideDirection === "右") {
            temporary = 2;
          }
        }
        data.data.machineRoomForm.slideDirection = temporary;
        if (data.data.point === 0) {
          data.data.controlRoomForm.doorLocation = homedataInfo.controlRoomForm.doorLocation + homedataInfo.controlRoomForm.location
        }
      }
      // patientPosition:病患通道方位 0上;1下; 2左; 3右; slideDirection:平移门滑动方向 0上;1下;2左;3右 现改为0:左（下） 1:右（上）
      if (window.state3dmax === 0 && data.type !== "0") {
        if (data.data.machineRoomForm.patientPosition === 3) {
          if (data.data.machineRoomForm.patientDirection === 0) {
            data.data.machineRoomForm.patientDirection = 1;
          } else if (data.data.machineRoomForm.patientDirection === 1) {
            data.data.machineRoomForm.patientDirection = 0;
          }
        }
        if (data.data.point === 1) {
          if (data.data.zhuForm.length > 0) {
            data.data.zhuForm.forEach((val, i) => {
              if (val.x < 0) {
                val.x = 0;
              }
              val.x =
                homedataInfo.machineRoomForm.length -
                homedataInfo.zhuForm[i].x -
                val.height;
              // if(val.y > homedataInfo.machineRoomForm.width){
              //   val.y = val.y - homedataInfo.machineRoomForm.wallThickness
              // }
            });
          }
        }
      }
      window.state3dmax++;
      // if (this.step >= 3 && data.type !== "0" && this.state3d === 0) {
      //   data.data.controlRoomForm.doorLocation =
      //     data.data.controlRoomForm.doorLocation -
      //     data.data.controlRoomForm.location;
      //   this.state3d++;
      // }
      if (data.type !== "0" && data.data.point === 1) {
        if (data.data.controlRoomForm.doorPosition === 3) {
          data.data.controlRoomForm.doorLocation =
            homedataInfo.controlRoomForm.doorLocation +
            homedataInfo.machineRoomForm.width;
        }
      }
      if (data.type == "1") {
        if (sessionStorage.getItem("kongzhishi") == "false") {
          (data.data.controlRoomForm.location = 0), //控制室位置
            (data.data.controlRoomForm.length = 0), // 控制室长度
            (data.data.controlRoomForm.width = 0), //控制室宽度
            // data.data.controlRoomForm.windowLocation = 174, //观察窗位置
            // data.data.controlRoomForm.windowWidth = 140, //观察窗宽度
            (data.data.controlRoomForm.doorPosition = 0), //控制室门方位;1下;2左;3右
            (data.data.controlRoomForm.doorType = 0), //控制室门类型 0单开门; 1双开门
            (data.data.controlRoomForm.doorLocation = 0), //控制室门位置
            (data.data.controlRoomForm.doorWidth = 0); //控制室门宽度
          data.data.controlRoomForm.doorDirection = 0; //控制室门朝向;0开向走廊;1开向控制室
        }
      }
      if (data.type == "1" && data.data.point === 1) {
        if (data.data.controlRoomForm.doorPosition === 1) {
          data.data.controlRoomForm.doorLocation =
            homedataInfo.machineRoomForm.length -
            homedataInfo.controlRoomForm.doorLocation -
            homedataInfo.controlRoomForm.doorWidth -
            homedataInfo.controlRoomForm.location;
        }
      }
      console.log(data, '0000000000000000')
      if(sessionStorage.getItem('isHaveKong') === 'true' && data.type == "1"){
        if(data.data.controlRoomForm.doorPosition != 1){
          data.data.controlRoomForm.doorLocation = homedataInfo.machineRoomForm.width + homedataInfo.machineRoomForm.wallThickness + homedataInfo.controlRoomForm.doorLocation
        }
      }
    },
    goBack() {
      this.$store.commit("changeStep", "");
      if (this.show3D == false) {
        //3d页面禁止后退
        if (this.step != 1) {
          this.step--;
        } else {
          this.step = localStorage.getItem("topStep");
        }
        if (this.step == 1) {
          this.$bus.$emit("backHome");
          this.$router.go(-1);
        }
        this.$bus.$emit("back", this.step);
      }
      localStorage.setItem("topStep", this.step);
    },
    goHome() {
      this.$router.push(
        `/home/?id=${localStorage.getItem("ThirdPartyProjectId")}`
      );
    },
  },
};
</script>
<style lang="scss">
body {
  min-height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  background-color: rgb(0, 0, 0, 0.07);
  height: 100vh;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.head {
  padding: 0 30px;
  width: 100%;
  height: 90px;
  font-size: 28px;
  color: #010101;
  font-weight: normal;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    margin-bottom: 0;
  }
  .backIcon {
    width: 21px;
    // height: 33px;
  }
  .backhomeIcon {
    width: 34px;
    // height: 33px;
  }
  p {
    margin-left: 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .product-name::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 25px;
      background-color: #000000;
      margin: 0 5px;
      margin-top: -5px;
      vertical-align: middle;
    }
  }
}
.steps {
  width: 100%;
  height: 8px;
  background: #d3d3d3;
  position: relative;
  span {
    position: absolute;
    display: inline-block;
    width: 14.2%;
    height: 100%;
    border-radius: 4px;
    background: #ec6602;
    &:nth-child(2) {
      left: 14.2%;
    }
    &:nth-child(3) {
      left: 28.4%;
    }
    &:nth-child(4) {
      left: 42.6%;
    }
    &:nth-child(5) {
      left: 56.8%;
    }
    &:nth-child(6) {
      left: 71%;
    }
    &:nth-child(7) {
      left: 85.4%;
    }
  }
}
.three {
  img {
    width: 56px;
    height: 48px;
    position: fixed;
    right: 4%;
    top: 10%;
  }
}
iframe {
  width: 100%;
  height: 92vh;
}
</style>
